.dawn-navbar {
  position: sticky;

  width: 100%;
  max-width: 100%;
  min-height: 32px;

  padding: 20px;

  box-sizing: border-box;

  background-color: var(--dawn-control-background);

  box-shadow: 2px 0px 5px 0px black;
}

.dawn-navbar-below {
  display: flex;
  justify-content: space-between;
}

.dawn-navbar-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.dawn-navbar-page-align {
  width: var(--dawn-page-width);
  margin-left: auto;
  margin-right: auto;
}

.dawn-breadcrumb {
  margin: 10px;
}

/* ----- Sidebar ----- */
.dawn-sidebar {
  height: 100%;
  width: 192px;

  padding: 10px;

  box-sizing: border-box;
  overflow-x: hidden;

  background-color: var(--dawn-container-secondary-background);
}

.dawn-sidebar-collapsed {
  width: 64px;
}

.dawn-sidebar-collapsed > .dawn-hoverable > .dawn-row > .material-icons {
  margin-left: -3px;
}

.dawn-sidebar-toggle {
  position: fixed;
  top: 0;
  right: 0;
  margin: 10px;

  border-radius: 100px;
}

.dawn-sidebar-mobile {
  width: 100vw;
}

.dawn-row:has(.dawn-sidebar-mobile) {
  overflow: hidden;
  height: 100vh;
}
