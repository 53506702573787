/* ----- From the util function ----- */

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dawn-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #00000044;
  z-index: 10;
}

.dawn-fullscreen-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(--dawn-control-background);
  z-index: 10;
}

.dawn-page-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dawn-round {
  border-radius: 100px;
}

.lift-up {
  position: relative;
}

.lift-up:hover {
  animation: ease-up 0.1s linear forwards;
}

@keyframes ease-up {
  0% {
    top: 0px;
  }

  100% {
    top: -10px;
  }
}

body:has(#root .dawn-fullscreen) {
  overflow: hidden;
}

.dawn-color-danger {
  color: var(--dawn-control-hover-danger);
}

.dawn-highlight {
  background-color: var(--dawn-accent);
  color: var(--dawn-accent-text);
}

.dawn-full-page {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.dawn-hoverable {
  border-radius: var(--dawn-border-radius);
  transition: var(--dawn-hover-transition);
}

.dawn-hoverable,
.dawn-hoverable > * > * {
  cursor: pointer;
}

.dawn-hoverable:hover {
  background-color: var(--dawn-control-hover) !important;
}

.dawn-selected,
.selected {
  background-color: var(--dawn-control-focus);
}

.dawn-big {
  box-sizing: border-box;
  width: 100%;
  font-size: 1.2em;
  padding: 10px;
  margin: 10px 0px;
  display: block;
  text-align: center;
}
