.dawn-context-menu {
  position: absolute;

  top: 0;
  left: 0;

  z-index: 100;

  min-width: 100px;
  width: fit-content;
  max-width: 200px;

  padding: 10px;

  border-radius: var(--dawn-control-border-radius);

  background-color: var(--dawn-popup-background);
}

.dawn-context-menu-button {
  padding: 10px 30px;
}
.dawn-context-menu-button,
.dawn-context-menu-button > label {
  cursor: pointer;
}

.dawn-context-menu-button:hover {
  background-color: var(--dawn-control-hover);
}

.dawn-context-menu-button-danger:hover {
  background-color: var(--dawn-control-hover-danger);
}

.dawn-context-menu-button-danger:hover > label {
  color: var(--dawn-control-text-danger);
}
